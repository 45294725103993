<template>
  <main class="main page-inside faq-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title regular light-green">General Questions</div>
        </div>
        <img src="./../img/welcome2.png" class="img"/>
      </div>
      <div class="section faq-section">
        <div class="wrapper">
          <div class="accordion">
            <div class="item" v-for="(item, i) in $parent.faq" :key="i">
              <div class="panel" @click="togglePanel(i)" >
                <Transition>
                  <img src="./../img/minus.svg" class="minus" v-if="isActivePanel(i)"/>
                </Transition>
                <Transition>
                  <img src="./../img/plus.svg" class="plus" v-if="!isActivePanel(i)"/>
                </Transition>
                <div class="panel-wrapper">
                  <div :class="['desc normal montserrat', {'orange': isActivePanel(i)}]">{{ item.question }}</div>
                </div>
              </div>
              <div class="preview" v-if="isActivePanel(i)">
                <div class="desc">{{ item.answer }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Social :social-links="$parent.socialLinks" />
    </div>
  </main>
</template>
<script>
import Social from '../components/Social.vue'

export default {
  name: 'Faq',
  props: [],
  components: {
    Social
  },
  data: function() {
    return {
      activePanels: []
    }
  },
  mounted() {
    
  },
  methods: {
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
  }
}
</script>