<template>
  <main class="main page-inside products-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title regular light-green">
            Shop
          </div>
        </div>
        <img src="./../img/welcome2.png" class="img"/>
      </div>
      <div class="section products-section">
        <div class="wrapper">
          <router-link to="/categories" class="button back">
            <img src="./../img/back.svg" class="back-img"/>
            <span>BACK</span>
          </router-link>
          <div class="desc desc-main normal montserrat">
            {{activeCategory.title}}
          </div>
          <transition name="fade">
            <div class="list products" v-if="products">
              <div class="item" v-for="(item, i) in products" :key="i">
                <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <Social :social-links="$parent.socialLinks" />
    </div>
  </main>
</template>
<script>
import Social from '../components/Social.vue'
import ProductCard from '../components/ProductCard.vue';
export default {
  name: 'Products',
  props: ['addToCartChosenItem', 'currency', 'activeCategory'],
  components: {
    ProductCard,
    Social
  },
  data: function() {
    return {
      products: [],
      category: '' 
    }
  },
  watch: {
    activeCategory: function() {
      this.getProducts();
    },
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    
    getProducts() {
      this.$http.get(process.env.VUE_APP_API + 'products/?category_id=' + this.activeCategory.id)
      .then((res) => {
        this.products = res.data.payload;
      })
      .catch(() => {
         
      })
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
  }
}
</script>