<template>
  <main class="main cart-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title regular light-green">
            Shopping cart
          </div>
        </div>
        <img src="./../img/welcome2.png" class="img"/>
      </div>
      <section class="section cart-section">
        <div class="wrapper">
          <div class="title regular empty-desc" v-if="!$parent.cartContents.length">
            Cart is empty!
          </div>
          <div class="left" v-if="$parent.cartContents.length">
            <div class="product-list">
              <div class="item" v-for="(item, i) in $parent.cartContents" :key="i">
                <div class="preview-container">
                  <div class="preview">
                    <img :src="imgDomain + item.item.img_url" class="img"/>
                  </div>
                  <div class="desc normal light-green montserrat name">{{item.item.title}}</div>
                </div>
                <div class="price-container">
                  <div class="count">
                    <div class="count__item minus" @click="$parent.removeOneFromCart(item, item.item_id)"></div>
                    <input type="number" value="1" v-model="item.count" step="1"/>
                    <div class="count__item plus" @click="$parent.addToCart(item.item, item.item_id)"></div>
                  </div>
                  <div class="price title small">{{item.item.price}} <span class="currency">{{$parent.currency}}</span></div>
                </div>
                <div class="delete" @click="$parent.removeFromCart(item.item, item.item_id)">
                  <img src="./../img/bin.svg" class="img"/>
                  <div class="desc orange">Delete</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right" v-if="$parent.cartContents.length">
            <div class="wrapper">
              <div class="desc normal montserrat light-green">TOTAL PRICE:</div>
              <div class="title regular">
                <b>{{$parent.totalSum}} <span class="currency">{{$parent.currency}}</span></b>
              </div>
              <button @click="toOrder" class="button">
                <span>Order</span>
              </button>
              <div class="delete" @click="$parent.emptyCart">
                <img src="./../img/bin.svg" class="img"/>
                  <div class="desc orange">Clear cart</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Social :social-links="$parent.socialLinks" />
    </div>
  </main>
</template>
<script>
import Social from '../components/Social.vue'

export default {
  name: 'Cart',
  props: [],
  components: {
    Social
  },
  data: function() {
    return {
      imgDomain: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    toOrder() {
      this.$router.push({ path: '/order' })
    }
  }
}
</script>