<template>
  <div class="page-inside order-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title regular light-green">
            Shopping cart
          </div>
        </div>
        <img src="./../img/welcome2.png" class="img"/>
      </div>
      <section class="section order-section">
        <div class="wrapper">
          <router-link to="/cart" class="button back">
            <img src="./../img/back.svg" class="back-img"/>
            <span>BACK</span>
          </router-link>
          <div class="desc desc-main normal montserrat">
            CHECKOUT
          </div>
          <div class="container">
            <div class="form">
              <label>
                <div class="desc">First name:</div>
                <input type="text" placeholder="First name" v-model="name"/>
              </label>
              <label>
                <div class="desc">Last Name:</div>
                <input type="text" placeholder="Last Name" v-model="surname"/>
              </label>
              <label>
                <div class="desc">Email:</div>
                <input type="email" placeholder="Email" v-model="email"/>
              </label>
              <label>
                <div class="desc">Nickname:</div>
                <input type="text" placeholder="Nickname" v-model="nickname"/>
              </label>
              <label>
                <div class="desc">Phone:</div>
                <input type="number" placeholder="Phone" v-model="phone"/>
              </label>
              <label>
                <div class="desc">Address:</div>
                <input type="text" v-model="address"/>
              </label>
              <label>
                <div class="desc">City:</div>
                <input type="text" v-model="city"/>
              </label>
              <label>
                <div class="desc">Country:</div>
                <select v-model="country">
                  <option v-for="item in countryOptions" :key="item.id">{{item.title}}</option>
                </select>
              </label>
              <label>
                <div class="desc">Zip Code:</div>
                <input type="text" v-model="zipCode"/>
              </label>
              <div class="cta-container">
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms"/>
                      <div class="checkbox"></div>
                      <span class="desc ">I agree with </span>
                      <a @click="$parent.goToPrivacy()" class="desc orange"> privacy policy</a>
                      <span class="desc"> and </span>
                      <a @click="$parent.goToTerms()" class="desc orange"> terms and conditions</a>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <button :class="['button action', {'disabled': !requiredFieldsAreFilled}]" @click="submit">
              <span>Debit / Credit card</span>
            </button>
            <transition name="slide">
              <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
            </transition>
          </div>
        </div>
      </section>
      <Social :social-links="$parent.socialLinks" />
    </div>
  </div>
</template>

<script>
import Social from '../components/Social.vue'

export default {
  name: 'Registration',
  props: [],
  components: {
    Social
  },
  data: function() {
    return {
      email: '',
      name: '',
      phone: '',
      surname: '',
      nickname: '',
      country: '',
      countryId: '',
      countryOptions: '',
      city: '',
      address: '',
      zipCode: '',
      terms: false
    }
  },
  mounted() {
    this.getProfile();
    this.getCounties();
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.countryId && this.city && this.address  
        && this.zipCode && this.terms && this.email && this.nickname
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  watch: {
    country: function() {
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.country === this.countryOptions[i].title) {
          this.countryId = this.countryOptions[i].id
          break;
        }
      }
      this.countryId
    },
  },
  methods: {
    submit() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "email": this.email,
        "nickname": this.nickname,
        "country": this.countryId,
        "city": this.city,
        "address": this.address,
        "postCode": this.zipCode
      }
      this.$emit('orderSubmit', data)
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.email = res.data.email;
          this.nickname = res.data.nickname;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
  }
}
</script>