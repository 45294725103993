<template>
	<header class="header">
        <div class="wrapper">
          <div class="left">
            <router-link to="/" class="logo">
              <div class="desc montserrat">MINE<b>WANDER</b><span class="yellow">.com</span></div>
            </router-link>
          </div>
          <ul class="nav">
            <template  v-for="item in $parent.nav">
              <li class="nav__item" v-if="item.path" :key="item.title">
                <router-link class="desc montserrat" :to="item.path">
                  <b>{{item.title}}</b>
                </router-link>
              </li>
              <li class="nav__item" :key="item.title"  v-if="!item.path">
                <a class="desc montserrat" @click="$emit('scrollToSection', item.scrollSection)">
                  <b>{{item.title}}</b>
                </a>
              </li>
            </template>
          </ul>
          <div class="right">
            <router-link to="/profile" class="link-container profile-link" v-if="$parent.isAuth">
              <img src="./../img/user.svg" class="img"/>
              <!-- <a @click="$parent.logout">
                <span>LogOut</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
                  <path id="log-out_1_" data-name="log-out (1)" d="M5,18a1,1,0,0,0,1,1h8a1,1,0,0,1,0,2H6a3,3,0,0,1-3-3V6A3,3,0,0,1,6,3h8a1,1,0,0,1,0,2H6A1,1,0,0,0,5,6Zm4-6a1,1,0,0,1,1-1h8.586L17.293,9.707a1,1,0,0,1,1.414-1.414l3,3a1,1,0,0,1,0,1.414l-3,3a1,1,0,0,1-1.414-1.414L18.586,13H10A1,1,0,0,1,9,12Z" transform="translate(-3 -3)" fill="#fff" fill-rule="evenodd"/>
                </svg>
              </a> -->
            </router-link>
            <router-link to="/cart" class="cart-icon button" v-if="$parent.isAuth">
              <img src="./../img/cart.svg" class="img"/>
              <span class="desc">
                <b>CART</b>
              </span>
              <div v-if="$parent.cartContents.length" class="indicator">
                {{$parent.cartContents.length}}
              </div>
            </router-link>
            <div class="link-container" v-else>
              <a class="desc signin" @click="$parent.openSignIn()">
                <span class="orange">Sign In</span>
              </a>
              <div class="button" @click="$parent.openSignUp()">
                <span>Sign Up</span>
              </div>
            </div>
          </div>
        </div>
    </header>
</template>

<script>

export default {
	name: 'Header',
  props: [],
	data: function() {
		return {
		}
	},
	methods: {
    
	}
}
</script>
