import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from './pages/PageNotFound.vue'
import Home from './pages/Home.vue'
import Profile from './pages/Profile.vue'
import PaymentSuccessPage from './pages/PaymentSuccessPage.vue'
import PaymentFaliPage from './pages/PaymentFaliPage.vue'
import TextPage from './pages/TextPage.vue'
import Login from './pages/Login.vue'
import Registration from './pages/Registration.vue'
import Recover from './pages/Recover.vue'
import ContactUs from './pages/ContactUs.vue'
import Products from './pages/Products.vue'
import Cart from './pages/Cart.vue'
import Order from './pages/Order.vue'
import Categories from './pages/Categories.vue'
import Rules from './pages/Rules.vue'
import Faq from './pages/Faq.vue'



Vue.use(VueRouter)

export default new VueRouter({
	mode: 'history',
	routes: [
		//{ path: '/', redirect: '/home' },
		{
			path: '/',
			name: 'Home',
			component: Home
		},
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/recover',
			name: 'Recover',
			component: Recover
		},
		{
			path: '/registration',
			name: 'Registration',
			component: Registration
		},
		{
			path: '/profile',
			name: 'Profile',
			component: Profile
		},
		{
			path: '/success-payment',
			name: 'PaymentSuccessPage',
			component: PaymentSuccessPage
		},
		{
			path: '/fail-payment',
			name: 'PaymentFaliPage',
			component: PaymentFaliPage
		},
		{
			path: '/pages/:id',
			name: 'TextPage',
			component: TextPage
		},
		{
			path: '/contacts',
			name: 'ContactUs',
			component: ContactUs
		},
		{
			path: '/products/:url',
			name: 'Products',
			component: Products
		},
		{
			path: '/categories',
			name: 'Categories',
			component: Categories
		},
		{
			path: '/cart',
			name: 'Cart',
			component: Cart
		},
		{
			path: '/order',
			name: 'Order',
			component: Order
		},
		{
			path: '/faq',
			name: 'Faq',
			component: Faq
		},
		{
			path: '/rules',
			name: 'Rules',
			component: Rules
		},
		{ 
			path: "*", 
			name: 'PageNotFound',
			component: PageNotFound,
			props: {title: "PageNotFound"}
		}
	]
})

