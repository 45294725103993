<template>
<div class="section section-4">
  <div class="wrapper social">
    <img src="./../img/decor1.svg" class="decor1"/>
    <img src="./../img/decor1.svg" class="decor2"/>
    <div class="title big">SOCIAL MEDIA</div>
    <div class="list">
      <a v-for="link in socialLinks" :href="link.link" class="item" :key="link.img">
        <div class="image">
          <img :src="link.img" class="img"/>
        </div>
        <div class="text">
          <div class="desc normal montserrat">{{ link.title }} SUBSCRIBE</div>
        </div>
      </a>
    </div>
  </div>
</div>


</template>

<script>

export default {
	name: 'Social',
	props: ['socialLinks'],
	data: function() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>
