<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="welcome">
            <img src="./../img/welcome3.png" class="img3"/>
            <img src="./../img/welcome1.svg" class="img1"/>
            <img src="./../img/welcome4.png" class="img4"/>
            <img src="./../img/welcome2.png" class="img2"/>
          </div>
          <div class="hero-bottom">
            <div class="left">
              <div class="desc regular montserrat">
                DIVE INTO ENDLESS MINECRAFT ADVENTURES WITH EVERY NEW CONNECTION!
              </div>
            </div>
            <div class="center">
              <div class="desc">
                <span class="yellow">
                  Discover new worlds, new friends, and new challenges
                </span>
              </div>
              <div class="ip">
                <a class="button" @click="copy">
                  Copy IP
                  <img src="./../img/copy.svg" class="img"/>
                </a>
                <div class="desc">{{ ipCopied ? 'Copied' : ip }}</div>
                <button class="button">CHANGE IP</button>
              </div>
            </div>
            <div class="right">
              <div class="desc">
                Welcome to Minewander, your portal to the many universes of Minecraft, where every click takes you into a new dimension of the game. A variety of servers await you, from arid survival landscapes to majestic kingdoms.
              </div>
              <div class="desc montserrat normal">
                ADVENTURE IS JUST A CLICK AWAY!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-2" id="howItWorks">
        <div class="wrapper">
          <div class="title big">How It Works</div>
          <div class="desc normal montserrat desc-main">EXPLORE SEAMLESS SERVER SWITCHING</div>
          <div class="accordion">
            <div class="item">
              <div class="panel" @click="togglePanel(1)">
                <Transition>
                  <img src="./../img/minus.svg" class="minus" v-if="isActivePanel(1)"/>
                </Transition>
                <Transition>
                  <img src="./../img/plus.svg" class="plus" v-if="!isActivePanel(1)"/>
                </Transition>
                <div class="panel-wrapper">
                  <div :class="['desc normal montserrat', {'orange': isActivePanel(1)}]">Copy IP</div>
                  <div class="desc normal montserrat last">01</div>
                </div>
              </div>
              <div class="preview" v-if="isActivePanel(1)">
                <div class="desc">
                  With a single click, grab a unique server IP that pops up on your screen. Each IP is your key to a new adventure.
                </div>
                <div class="img-container">
                  <img src="./../img/how-it-works/1.png" class="img" />
                </div>
              </div>
            </div>
            <div class="item">
              <div class="panel" @click="togglePanel(2)">
                <Transition>
                  <img src="./../img/minus.svg" class="minus" v-if="isActivePanel(2)"/>
                </Transition>
                <Transition>
                  <img src="./../img/plus.svg" class="plus" v-if="!isActivePanel(2)"/>
                </Transition>
                <div class="panel-wrapper">
                  <div :class="['desc normal montserrat', {'orange': isActivePanel(2)}]">Paste in Game</div>
                  <div class="desc normal montserrat last">02</div>
                </div>
              </div>
              <div class="preview" v-if="isActivePanel(2)">
                <div class="desc">
                  Launch your Minecraft, go to 'Multiplayer', and enter the copied IP into the 'Server Address' box. You're all set for an epic journey!
                </div>
                <div class="img-container">
                  <img src="./../img/how-it-works/2.png" class="img" />
                </div>
              </div>
            </div>
            <div class="item">
              <div class="panel" @click="togglePanel(3)">
                <Transition>
                  <img src="./../img/minus.svg" class="minus" v-if="isActivePanel(3)"/>
                </Transition>
                <Transition>
                  <img src="./../img/plus.svg" class="plus" v-if="!isActivePanel(3)"/>
                </Transition>
                <div class="panel-wrapper">
                  <div :class="['desc normal montserrat', {'orange': isActivePanel(3)}]">Change Worlds Easily</div>
                  <div class="desc normal montserrat last">03</div>
                </div>
              </div>
              <div class="preview" v-if="isActivePanel(3)">
                <div class="desc">
                  Craving a change? Head back to Minewander to get a fresh new IP and embark on another adventure. Exploration has no limits!
                </div>
                <div class="img-container">
                  <img src="./../img/how-it-works/3.png" class="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-3">
        <img src="./../img/portal.png" class="bg"/>
        <div class="wrapper">
          <div class="title big">Features</div>
          <div class="list">
            <div class="item">
              <div class="desc normal montserrat">
                <span class="yellow">ADVENTURE IS JUST A <br/>CLICK AWAY!</span>
              </div>
              <div class="desc">
                Each server is a distinct world with its own rules and features. You never know what’s next!
              </div>
            </div>
            <div class="item">
              <div class="desc normal montserrat">
                <span class="yellow">EASY <br/>SHARING</span>
              </div>
              <div class="desc">
                Copy IP with one click and share the unique world with friends. Make your adventures even more exciting!
              </div>
            </div>
            <div class="item">
              <div class="desc normal montserrat">
                <span class="yellow">UNLIMITED <br/>CHANGES</span>
              </div>
              <div class="desc">
                Infinite server switches—play by your own rules, with no limits on where you can go next.
              </div>
            </div>
          </div>
          <div class="portal">
            <div class="left">
              <div class="title big">START YOUR RANDOM ADVENTURE TODAY</div>
            </div>
            <div class="center">
              <div class="ip">
                <a class="button">
                  Copy IP
                  <img src="./../img/copy.svg" class="img"/>
                </a>
                <div class="desc">192.383.375:2256</div>
                <button class="button">CHANGE IP</button>
              </div>
              <div class="desc center-desc">
                <a class="orange">
                  <b>Register now</b>
                </a> 
                and plunge into the endless world of Minecraft with Minewander
              </div>
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
      <Social :social-links="$parent.socialLinks" />
    </div>
  </main>
</template>
<script>

import Social from '../components/Social.vue'

export default {
  name: 'Home',
  props: [],
  components: {
    Social
  },
  data: function() {
    return {
      email: '',
      name: '',
      message: '',
      nickname: '',
      activePanels: [],
      ip: '91.108.122.207:25565',
      ipCopied: false
    }
  },
  mounted() {
  },
  methods: {
    copy() {
      let self = this;
      var textarea = document.createElement("textarea");
      textarea.value = self.ip;
      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);
      self.ipCopied = true;
      
      setTimeout(()=> {
        self.ipCopied = false;
      }, 2000)
    },
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
    submit() {
      let data = {
        "name": this.name,
        "email": this.email,
        "message": this.message
      }
      this.$emit('sendContactForm', data);
    }
  }
}
</script>