<template>
  <main class="main page-inside rules-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title regular light-green">
            Community Guidelines for Minewander
          </div>
        </div>
        <img src="./../img/welcome2.png" class="img"/>
      </div>
      <div class="section rules-section">
        <div class="wrapper">
          <div class="desc normal montserrat desc-main">
            WE'RE THRILLED TO HAVE YOU JOIN OUR EVER-GROWING COMMUNITY OF MINECRAFT ENTHUSIASTS. TO ENSURE EVERYONE HAS A POSITIVE AND ENJOYABLE EXPERIENCE, WE'VE ESTABLISHED A SET OF RULES. PLEASE TAKE A MOMENT TO FAMILIARIZE YOURSELF WITH THEM BEFORE DIVING INTO YOUR NEXT MINECRAFT ADVENTURE.
          </div>
        </div>
        <div class="rules-wrapper">
          <div class="title big">General Conduct</div>
          <div class="card">
            <div class="item">
              <div class="desc normal light-green">
                BE RESPECTFUL
              </div>
              <div class="desc">
                ● Treat all players with respect and courtesy. 
              </div>
              <div class="desc">
                ● Offensive language, harassment, or bullying is not tolerated.
              </div>
            </div>
            <div class="item">
              <div class="desc normal light-green">
                PLAY FAIR
              </div>
              <div class="desc">
                ● Cheating, exploiting bugs, or using unauthorized mods to gain an unfair advantage is strictly prohibited.
              </div>
            </div>
            <div class="item">
              <div class="desc normal light-green">
                NO GRIEFING
              </div>
              <div class="desc">
                ● Do not destroy, damage, or deface the structures or creations of other players. 
              </div>
              <div class="desc">
                ● Respect the boundaries and property of others.
              </div>
            </div>
            <div class="item">
              <div class="desc normal light-green">
                COMMUNITY ENGAGEMENT
              </div>
              <div class="desc">
                ● Encourage cooperation and positive interactions.
              </div>
              <div class="desc">
                ● Help newcomers feel welcome and guide them as needed.
              </div>
            </div>
          </div>
        </div>
        <div class="rules-wrapper">
          <div class="title big">Communication Guidelines</div>
          <div class="card">
            <div class="item">
              <div class="desc normal light-green">
                APPROPRIATE LANGUAGE
              </div>
              <div class="desc">
                ● Use language that is appropriate for a diverse audience. 
              </div>
              <div class="desc">
                ● Avoid swearing, and derogatory remarks.
              </div>
            </div>
            <div class="item">
              <div class="desc normal light-green">
                PRIVACY AND SAFETY
              </div>
              <div class="desc">
                ● Do not share personal information about yourself or others. 
              </div>
              <div class="desc">
                ● Report any suspicious or harmful activity to the moderators immediately.
              </div>
            </div>
            <div class="item">
              <div class="desc normal light-green">
                CONSTRUCTIVE FEEDBACK
              </div>
              <div class="desc">
                ● When giving feedback, be constructive and polite. 
              </div>
              <div class="desc">
                ● Criticism should be aimed at improving the experience, not demeaning others.
              </div>
            </div>
          </div>
        </div>
        <div class="rules-wrapper">
          <div class="title big">Building and Creativity</div>
          <div class="card">
            <div class="item">
              <div class="desc normal light-green">
                CREATIVE FREEDOM
              </div>
              <div class="desc">
                ● Feel free to express yourself through your builds, but ensure they are appropriate and respectful to others.
              </div>
            </div>
            <div class="item">
              <div class="desc normal light-green">
                NO DISRUPTION
              </div>
              <div class="desc">
                ● Do not build in a way that intentionally disrupts or interferes with other players’ experiences.
              </div>
            </div>
            <div class="item">
              <div class="desc normal light-green">
                COMMUNITY PROJECTS
              </div>
              <div class="desc">
                ● When participating in community projects, work collaboratively and follow the project's guidelines.
              </div>
            </div>
          </div>
        </div>
        <div class="rules-wrapper">
          <div class="title big">
            Enforcement and Reporting
          </div>
          <div class="card">
            <div class="item">
              <div class="desc normal light-green">
                MODERATION
              </div>
              <div class="desc">
                ● Our moderators are here to help enforce these rules and ensure a safe environment. 
              </div>
              <div class="desc">
                ● If you see any rule violations, please report them through our designated channels.
              </div>
            </div>
            <div class="item">
              <div class="desc normal light-green">
                CONSEQUENCES
              </div>
              <div class="desc">
                ● Violations of these rules may result in a warning, a temporary ban, or in severe cases, a permanent ban. 
              </div>
              <div class="desc">
                ● We reserve the right to remove any player who consistently disrupts the community or violates the rules.
              </div>
            </div>
          </div>
        </div>
        <div class="desc normal montserrat desc-main">
          THANK YOU FOR HELPING US MAINTAIN A FRIENDLY AND FUN ENVIRONMENT FOR EVERYONE. BY FOLLOWING THESE GUIDELINES, YOU CONTRIBUTE TO A RESPECTFUL AND ENJOYABLE GAMING EXPERIENCE FOR ALL. LET’S BUILD A VIBRANT AND POSITIVE MINECRAFT COMMUNITY TOGETHER!
        </div>
      </div>
      <Social :social-links="$parent.socialLinks" />
    </div>
  </main>
</template>
<script>
import Social from '../components/Social.vue'

export default {
  name: 'Rules',
  props: [],
  components: {
    Social
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>