<template>
	<div class="item-wrapper">
		<div class="desc normal montserrat name">{{item.title}}</div>
		<div class="preview">
			<transition name="fade">
				<span class="title" v-if="addToCartChosenItem == item.id">Added</span>
			</transition>
			<img :src="imgDomain  + item.image_url" class="img"/>
		</div>
		<div class="left">
			<div class="price title small">
				<span>{{item.price}}<span class="currency">{{currency}}</span></span>
			</div>
		</div>
		<button class="button" @click="$emit('addToCart',item, item.id)">BUY</button>
	</div>
</template>

<script>

export default {
	name: 'ProductCard',
	props: ['item', 'currency', 'addToCartChosenItem'],
	data: function() {
		return {
			imgDomain: ''
		}
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		
	},
}
</script>
