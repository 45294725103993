<template>
  <div class="modal sign-modal">
    <div 
      class="overlay"
      @click="$parent.closeSignIn"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img 
              class="close" 
              src="./../img/close.svg"
              @click="$parent.closeSignIn"
            >
            <a class="modal-back">
              <img 
                v-if="!loginContentVisible"
                class="modal-back-img" 
                src="./../img/back.svg"
                @click="switchToLogin"
              >
            </a>
            <div class="right">
              <div :class="['form-fields login-content', {'hidden': !loginContentVisible}]">
                <div class="form-fields-wrapper">
                  <div class="desc montserrat normal desc-main">
                    Sign In
                  </div>
                  <label>
                    <div class="desc">
                      E-mail
                    </div>
                    <input 
                      v-model="email"
                      type="email" 
                      placeholder="Email"
                    >
                  </label>
                  <label>
                    <div class="desc">
                      Password
                    </div>
                    <input 
                      v-model="pass"
                      type="password" 
                      placeholder="Password"
                    >
                  </label>
                  <div class="modal-bottom">
                    <a 
                      class="desc orange" 
                      @click="switchToforgotPass"
                    >
                      Forgot password?
                    </a>
                  </div>
                  <div class="cta-container">
                    <button 
                      class="button blue" 
                      @click="submitLogin"
                    >
                      <span>
                        LOGIN
                      </span>
                    </button>
                  </div>
                  <transition name="slide">
                    <div 
                      v-if="$parent.error" 
                      class="error-desc desc red"
                    >
                      {{ $parent.error }}
                    </div>
                  </transition>
                </div>
              </div>
              <div :class="['form-fields forgot-pass-content', {'hidden': !forgotPassContentVisible}]">
                <div class="form-fields-wrapper">
                  <div class="desc montserrat normal desc-main">
                    Forgot password?
                  </div>
                  <label>
                    <div class="desc">E-mail:</div>
                    <input 
                      v-model="email"
                      type="email" 
                      placeholder="Email" 
                    >
                  </label>
                  <div class="cta-container">
                    <button 
                      class="button blue" 
                      @click="submitForgotPass"
                    >
                      <span>
                        Recover
                      </span>
                    </button>
                  </div>
                  <transition name="fade">
                    <div 
                      v-if="$parent.successMessage" 
                      class="desc green"
                    >
                      {{ $parent.successMessage }}
                    </div>
                  </transition>
                  <transition name="slide">
                    <div 
                      v-if="$parent.error" 
                      class="error-desc desc red"
                    >
                      {{ $parent.error }}
                    </div>
                  </transition>
                </div>
              </div>
            </div>
            <div class="left">
              <div class="desc montserrat normal desc-main">
                CREATE ACCOUNT
              </div>
              <div class="desc">
                Enter your personal details and start game!
              </div>
              <a 
                class="desc orange" 
                @click="$parent.openSignUp"
              >
                <span>
                  Sign Up
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  props: [],
  data: function() {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: '',
      pass: ''
    }
  },
  mounted() {
    
  },
  methods: {
    switchToforgotPass() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100)
    },
    switchToLogin() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100)
    },
    submitLogin() {
      let loginData = {
        "username": this.email,
        "password": this.pass
      }
      this.$emit('login', loginData)
    },
    submitForgotPass() {
      let data = {
        "email": this.email
      }
      this.$emit('forgotPass', data)
    },
  }
}
</script>