<template>
  <div class="modal sign-modal reg-modal">
    <div 
      class="overlay"
      @click="$parent.closeSignUp"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img 
              class="close" 
              src="./../img/close.svg"
              @click="$parent.closeSignUp" 
            >
            <div class="right">
              <div class="form-fields register-content">
                <div class="form-fields-wrapper">
                  <div class="desc montserrat normal desc-main">
                    Sign Up
                  </div>
                  <div class="label-list">
                    <label>
                      <div class="desc">
                        *First name
                      </div>
                      <input 
                        v-model="name"
                        type="text" 
                        placeholder="First name"
                      >
                    </label>
                    <label>
                      <div class="desc">*Last Name</div>
                      <input 
                        v-model="surname"
                        type="text"
                        placeholder="Last Name" 
                      >
                    </label>
                    <label>
                      <div class="desc">*Phone</div>
                      <input 
                        v-model="phone"
                        type="text" 
                        placeholder="Phone"
                        @input="allowOnlyNumbers" 
                      >
                    </label>
                    <label>
                      <div class="desc">*Email</div>
                      <input 
                        v-model="email"
                        type="email"
                        placeholder="Email"
                      >
                    </label>
                    <label>
                      <div class="desc">*Password</div>
                      <input
                        v-model="pass" 
                        type="password"
                        placeholder="Password"
                      >
                    </label>
                    <label>
                      <div class="desc">*Confirm Password</div>
                      <input 
                        v-model="passConfirm"
                        type="password" 
                        placeholder="Confirm Password"
                      >
                    </label>
                  </div>
                  <div class="cta-container">
                    <div class="chekbox-container">
                      <label class="chekbox-label">
                        <div class="chekbox-label-wrapper">
                          <input 
                            v-model="terms"
                            type="checkbox" 
                            name="terms" 
                          >
                          <div class="checkbox" />
                          <span class="desc">I agree with </span>
                          <a 
                            class="desc orange"
                            @click="$parent.goToPage('privacy')"
                          > 
                            privacy policy
                          </a>
                          <span class="desc"> and </span>
                          <a 
                            class="desc orange"
                            @click="$parent.goToPage('terms')"
                          > 
                            terms and conditions
                          </a>
                        </div>
                      </label>
                    </div>
                    <button 
                      :class="['button blue', {'disabled': !requiredRegisterFieldsAreFilled}]" 
                      @click="submitRegister"
                    >
                      <span>
                        Sign up
                      </span>
                    </button>
                  </div>
                  <transition name="slide">
                    <div 
                      v-if="$parent.error" 
                      class="error-desc desc red"
                    >
                      {{ $parent.error }}
                    </div>
                  </transition>
                </div>
              </div>
            </div>
            <div class="left">
              <div class="desc montserrat normal desc-main">
                HAVE AN ACCOUNT?
              </div>
              <div class="desc">
                Please login with your personal info
              </div>
              <a 
                class="desc orange" 
                @click="$parent.openSignIn"
              >
                <span>
                  Sign In
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Registration',
  props: [],
  data: function() {
    return {
      email: '',
      pass: '',
      name: '',
      surname: '',
      phone: '',
      passConfirm: '',
      terms: false
    }
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.email && this.pass && this.passConfirm
        && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  mounted() {
    
  },
  methods: {
    allowOnlyNumbers(){
      this.phone = this.phone.replace(/[^0-9.,]/g, '');
      const parts = this.phone.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }
      this.phone = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    closeSignUpModal() {
      this.$emit('closeSignIn')
    },
    openSignInModal() {
      this.$emit('openSignIn')
    },
    submitRegister() {
      let regData = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phone,
        "password": this.pass,
        "passConfirm": this.passConfirm
      }
      this.$emit('registration', regData)
    }
  }
}
</script>