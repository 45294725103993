<template>
  <main class="main page-inside text-page contact-us-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="title big">
          CONTACTS
        </div>
        <div class="wrapper">
          <div class="content form-fields-wrapper">
            <label>
              <div class="desc">Name:</div>
              <input type="text" placeholder="Name" v-model="name"/>
            </label>
            <label>
              <div class="desc">Email:</div>
              <input type="text" placeholder="Email" v-model="email"/>
            </label>
            <label>
              <div class="desc">Message</div>
              <textarea placeholder="Message" v-model="message"></textarea>
            </label>
            <button class="button" @click="submit">Send</button>
            <transition name="fade">
              <div v-if="$parent.successMessage" class="desc small green">{{$parent.successMessage}}</div>
            </transition>
            <transition name="slide">
              <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
            </transition>
          </div>
          <div class="info">
            <div class="info-wrapper">
              <img src="./../images/maps-and-flags.svg" class="loc"/>
              <div class="desc">
                {{ $parent.settings && $parent.settings.requisites ? $parent.settings.requisites : ''}}
              </div>
              <img src="./../images/email.svg" class="mail"/>
              <div class="desc">
                {{ $parent.settings && $parent.settings.support_email ? $parent.settings.support_email : ''}}
              </div>
            </div>
          </div>
        </div>
        <div class="title small server-status absolute">SERVER STATUS: <span class="green">ONLINE</span></div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'ContactUs',
  props: [],
  components: {
    
  },
  data: function() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    submit() {
      let data = {
        "name": this.name,
        "email": this.email,
        "message": this.message
      }
      this.$emit('sendContactForm', data);
    },
  }
}
</script>