<template>
	<footer class="footer">
      <div class="footer-section">
        <div class="wrapper">
          <div class="left">
            <router-link to="/" class="logo">
              <img src="./../img/welcome2.png" class="img"/>
            </router-link>
            <div class="text">
              <div class="desc" v-if="settings">{{ settings.copyright }}</div>
              <div class="desc" v-if="settings">{{ settings.requisites }}</div>
            </div>
          </div>
          <div class="right">
            <ul class="nav">
              <template  v-for="item in $parent.nav">
                <li class="nav__item" v-if="item.path" :key="item.title">
                  <router-link class="desc montserrat" :to="item.path">
                    {{item.title}}
                  </router-link>
                </li>
                <li class="nav__item" :key="item.title"  v-if="!item.path">
                  <a class="desc montserrat" @scrollToSection="scrollToSection(item.scrollSection)">
                    {{item.title}}
                  </a>
                </li>
              </template>
            </ul>
            <ul class="nav">
              <template  v-for="item in textPageList">
                <li class="nav__item" :key="item.id">
                  <a class="desc" @click="goToPage(item)">
                    {{item.title}}
                  </a>
                </li>
              </template>
            </ul>
            <div class="methods">
              <div class="list">
                <img src="./../img/methods/visa.svg" class="visa"/>
                <img src="./../img/methods/mc.svg" class="mc"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: ['settings'],
	data: function() {
		return {
      textPageList: []
		}
	},
	methods: {
		getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch(() => {
        
      })
    },
    goToPage(item) {
      this.$router.push({path: '/pages/' + item.id, params: {data: item.id}})
      this.$emit('getTextPageContent', item.id);
    }
	},
	mounted() {
    this.getTextPages()
    
  }
}
</script>
